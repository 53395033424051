import {ScmIntegrationsApi, scmIntegrationsApiRef, ScmAuth} from '@backstage/integration-react';
import {AnyApiFactory, configApiRef, createApiFactory} from '@backstage/core-plugin-api';
import {analyticsApiRef, identityApiRef} from '@backstage/core-plugin-api';
import {GoogleAnalytics4} from '@backstage/plugin-analytics-module-ga4';
import {bitbucketUtilitiesApi} from './scaffolder/utils/bitbucketUtilitiesApi';
import {utilitiesApiRef} from './scaffolder/utils/bitbucketUtilitiesApi';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: {configApi: configApiRef},
    factory: ({configApi}) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: analyticsApiRef,
    deps: {configApi: configApiRef, identityApi: identityApiRef},
    factory: ({configApi, identityApi}) =>
      GoogleAnalytics4.fromConfig(configApi, {
        identityApi,
      }),
  }),
  createApiFactory({
    api: utilitiesApiRef,
    deps: {configApi: configApiRef},
    factory: ({configApi}) => bitbucketUtilitiesApi(configApi),
  }),
];
