import {useEffect, useRef} from 'react';

export const useHasChanged = <T>(value: T) => {
  const prevValueRef = useRef<T>();

  useEffect(() => {
    prevValueRef.current = value;
  });

  return prevValueRef.current !== value;
};
