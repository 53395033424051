import React, {useCallback, useState} from 'react';
import {useEntity, getEntitySourceLocation} from '@backstage/plugin-catalog-react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Divider,
  CardHeader,
} from '@material-ui/core';
import {useApi} from '@backstage/core-plugin-api';
import {scmIntegrationsApiRef} from '@backstage/integration-react';
import {utilitiesApiRef} from '../../../../scaffolder/utils/bitbucketUtilitiesApi';
import {useGetEmail} from '../../../../scaffolder/EmailExtension/useGetEmail';
import {makeStyles} from '@material-ui/core/styles';

const useDoesUserHavePermission = () => {
  const utilitiesApi = useApi(utilitiesApiRef);
  const doesUserHavePermission = useCallback(
    async (repoSlug: string, userEmail: string) => {
      const hasPermission = await utilitiesApi.doesUserHavePermission(repoSlug, userEmail);
      return hasPermission;
    },
    [utilitiesApi],
  );
  return doesUserHavePermission;
};

const useInviteUserToRepo = () => {
  const utilitiesApi = useApi(utilitiesApiRef);
  const inviteUserToRepo = useCallback(
    async (repoSlug: string, userEmail: string) => {
      const success = await utilitiesApi.inviteUser(repoSlug, userEmail);
      return success;
    },
    [utilitiesApi],
  );
  return inviteUserToRepo;
};

const getRepoSlugFromUrl = (url: string) => {
  const workspaceString = 'parivedasolutions/';
  const workspaceStart = url.indexOf(workspaceString);

  const branchString = '/src';
  const branchStart = url.indexOf(branchString);

  const repoSlug = url.substring(workspaceStart + workspaceString.length, branchStart);

  return repoSlug;
};

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  fullHeightCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  gridItemCardContent: {
    flex: 1,
  },
  fullHeightCardContent: {
    flex: 1,
  },
});

export function PermissionsCard() {
  const classes = useStyles();
  const email = useGetEmail(); // hard code for local testing
  // entity set up
  const {entity} = useEntity();
  const scmIntegrationsApi = useApi(scmIntegrationsApiRef);
  const entitySourceLocation = getEntitySourceLocation(entity, scmIntegrationsApi);
  const repoSlug = getRepoSlugFromUrl(entitySourceLocation!.locationTargetUrl);

  // button setup
  const [showCheckPermissionButton, setShowCheckPermissionButton] = useState<boolean>(false);
  const [showCheckPermissionSpinner, setShowCheckPermissionSpinner] = useState<boolean>(false);

  // permission set up
  const doesUserHavePermission = useDoesUserHavePermission();
  const [permissionText, setPermissionText] = useState(' ');

  // invitation setup
  const inviteUserToRepo = useInviteUserToRepo();
  const [hideInvitationButton, setHideInvitationButton] = useState(true);
  const invitationSentText = `Invitation has been sent to: ${email}`;
  const [invitationSent, setInvitationSent] = useState(false);

  const handleCheckPermission = async () => {
    setShowCheckPermissionButton(true);
    setShowCheckPermissionSpinner(true);
    const hasPermission = await doesUserHavePermission(repoSlug, email);
    setPermissionText(
      hasPermission
        ? 'You may have permission to this repo.'
        : 'You may not have permission to this repo.',
    );
    setShowCheckPermissionSpinner(false);
    setHideInvitationButton(hasPermission);
  };

  const handleInviteCurrentUser = async () => {
    const success = await inviteUserToRepo(repoSlug, email);
    setPermissionText(
      success
        ? 'You have been invited.'
        : 'There was an issue inviting you, please try again later.',
    );
    setInvitationSent(true);
  };

  return (
    <Card className={classes.gridItemCard}>
      <CardHeader title='Manage Permissions' />
      <Divider />
      <CardContent>
        <div hidden={showCheckPermissionButton} className={classes.gridItemCardContent}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleCheckPermission}
            style={{position: 'relative'}}
          >
            {showCheckPermissionSpinner ? (
              <CircularProgress
                size='30px' // Size of the spinner
                style={{
                  color: 'primary',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            ) : (
              'Check Permission'
            )}
          </Button>
        </div>
        <Typography>{permissionText}</Typography>
        <div hidden={hideInvitationButton}>
          <Button
            variant='contained'
            color='primary'
            style={{position: 'relative'}}
            onClick={handleInviteCurrentUser}
          >
            Request Permission
          </Button>
        </div>
        <Typography hidden={!invitationSent}>{invitationSentText}</Typography>
      </CardContent>
    </Card>
  );
}
