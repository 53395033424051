import {createTheme, genPageTheme, lightTheme, shapes, BackstageTheme} from '@backstage/theme';

import {BackstageOverrides} from '@backstage/core-components';

export const createCustomThemeOverrides = (): BackstageOverrides => {
  return {
    BackstageSidebarItem: {
      root: {
        '&:hover': {
          background: '#3078CD',
        },
      },
      highlightable: {
        '&:hover': {
          background: '#3078CD',
        },
      },
    },
    MuiCard: {
      root: {
        boxShadow:
          '1px 2px 6px 0px rgba(0,0,0,0.2), 0px 2px 1px 2px rgba(0,0,0,0.14), -1px 1px 0px 0px rgba(0,0,0,0.12)',
      },
    },
  };
};

export const parivedaBaseTheme: BackstageTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#343b58',
    },
    secondary: {
      main: '#565a6e',
    },
    error: {
      main: '#8c4351',
    },
    warning: {
      main: '#8f5e15',
    },
    info: {
      main: '#34548a',
    },
    success: {
      main: '#485e30',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    banner: {
      info: '#34548a',
      error: '#8c4351',
      text: '#343b58',
      link: '#565a6e',
    },
    text: {
      primary: '#444444',
    },
    errorBackground: '#8c4351',
    warningBackground: '#8f5e15',
    infoBackground: '#343b58',
    navigation: {
      background: '#010b3c',
      indicator: '#3078CD',
      color: '#d5d6db',
      selectedColor: '#ffffff',
    },
  },
  defaultPageTheme: 'home',
  fontFamily: 'Avenir Next,sans-serif',
  htmlFontSize: 14,
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({colors: ['#1A57D6'], shape: 'none'}),
    documentation: genPageTheme({
      colors: ['#13558C', '#343b58'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({colors: ['#13558C', '#343b58'], shape: shapes.round}),
    service: genPageTheme({
      colors: ['#13558C', '#343b58'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#13558C', '#343b58'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#13558C', '#343b58'],
      shape: shapes.wave,
    }),
    other: genPageTheme({colors: ['#13558C', '#343b58'], shape: shapes.wave}),
    app: genPageTheme({colors: ['#13558C', '#343b58'], shape: shapes.wave}),
    apis: genPageTheme({colors: ['#13558C', '#343b58'], shape: shapes.wave}),
  },
});

export const parivedaThemeWithOverrides: BackstageTheme = {
  ...parivedaBaseTheme,
  overrides: {
    // These are the overrides that Backstage applies to `material-ui` components
    ...parivedaBaseTheme.overrides,
    // These are your custom overrides, either to `material-ui` or Backstage components.
    ...createCustomThemeOverrides(),
  },
};
