import {useCallback, useState} from 'react';
import {useApi, configApiRef} from '@backstage/core-plugin-api';
import {utilitiesApiRef} from '../utils/bitbucketUtilitiesApi';

export const useSetRepoSlug = (initialSlug: string) => {
  const [repoSlug, setRepoSlug] = useState(initialSlug);
  return {repoSlug, setRepoSlug};
};

/**
 * Checks to see if a repository slug (name) is URL-friendly and valid for BitBucket Cloud
 * @param repoSlug
 * @returns True if valid, else False
 */
const checkRepoRegex = (repoSlug: string): boolean => {
  // Regular expression to validate Bitbucket Cloud repo names
  const repositoryNameRegex = /^[a-z0-9][a-z0-9._-]{0,62}(?<!\.)$/;

  /*
    Regex breakdown:

    ^                 : Start of the string
    [a-z0-9]          : First character must be a lowercase letter (a-z) or a digit (0-9)
    [a-z0-9._-]{0,62} : The following characters can be lowercase letters, digits, dots (.), dashes (-), or underscores (_)
                        Up to 62 characters are allowed after the initial one (making the total length 1 to 63 characters)
    (?<!\.)           : Negative lookbehind to ensure the string does not end with a dot (.)
    $                 : End of the string
  */
  return repositoryNameRegex.test(repoSlug);
};

export const useIsRepoSlugValid = () => {
  const configApi = useApi(configApiRef);
  const utilitiesApi = useApi(utilitiesApiRef);
  const isRepSlugValid = useCallback(
    async (repoSlug: string) => {
      const hasRepoName = !!repoSlug;
      const hasValidCharacters = checkRepoRegex(repoSlug);

      if (!hasRepoName || !hasValidCharacters) {
        return false;
      }
      const exists = await utilitiesApi.repoExists(repoSlug, configApi.get('backend.baseUrl'));
      return !exists;
    },
    [utilitiesApi, configApi],
  );
  return isRepSlugValid;
};
