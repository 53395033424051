import {createPlugin} from '@backstage/core-plugin-api';
import {
  createSearchResultListItemExtension,
  SearchResultListItemExtensionProps,
} from '@backstage/plugin-search-react';
import {MicrosoftGraphResultItemProps} from './types';

export const microsoftGraphSearchPlugin = createPlugin({
  id: 'microsoft-graph-search',
});

export const MicrosoftGraphResultListItem: (
  props: SearchResultListItemExtensionProps<MicrosoftGraphResultItemProps>,
) => JSX.Element | null = microsoftGraphSearchPlugin.provide(
  createSearchResultListItemExtension({
    name: 'MicrosoftGraphResultListItem',
    component: () =>
      import('./components/MicrosoftGraphResultListItem').then(m => m.MicrosoftGraphResultListItem),
    predicate: result => result.type === 'microsoftGraph',
  }),
);
