import {FormControl, InputLabel, Input, FormHelperText} from '@material-ui/core';
import React, {useState} from 'react';
import {getUiOptions} from '../../utils';
import {FieldProps, Options} from '../EmailExtension/schema';
import {useHasChanged} from '../../hooks';
import {useIsRepoSlugValid} from '../BitbucketRepoNameValidatorExtension/useIsRepoSlugValid';
import {slugify} from '../utils/bitbucketUtilities';

/*
Extension field to allow validation of project name against valid repo slugs
*/

export const ProjectNameValidatorField: React.FC<FieldProps> = ({
  formData,
  rawErrors,
  onChange,
  uiSchema,
}) => {
  const [repoSlug, setRepoSlug] = useState('ENTER A PROJECT NAME');
  let repoSlugIterator = '';
  const [projectName, setProjectName] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const validateRepoSlug = useIsRepoSlugValid();
  const {title, description} = getUiOptions<Options>(uiSchema);
  const hasChanged = useHasChanged(projectName);

  const handleBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    if (hasChanged) {
      setProjectName(event.target.value);
      onChange(event.target.value);
      repoSlugIterator = slugify(projectName);
      setRepoSlug(repoSlugIterator);
      const isValid = await validateRepoSlug(repoSlugIterator);
      if (isValid) {
        setValidationMessage('✅ Repo name is valid.');
      } else {
        setValidationMessage('❌ Invalid characters or a repo with this name already exists.');
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(event.target.value);
  };

  return (
    <FormControl error={rawErrors?.length > 0 && !formData}>
      <InputLabel>{title}</InputLabel>
      <Input value={projectName} onChange={handleChange} onBlur={handleBlur} />
      <FormHelperText>Repo Slug will be: {repoSlug}</FormHelperText>
      {/* describes the input */}
      <FormHelperText>{description}</FormHelperText>
      {/* shows if the project name is valid to be created in git */}
      <FormHelperText>{validationMessage}</FormHelperText>
    </FormControl>
  );
};
