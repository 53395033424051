import {useApi, identityApiRef, ProfileInfo} from '@backstage/core-plugin-api';
import {useEffect, useState} from 'react';

export const useGetEmail = () => {
  const [profile, setProfile] = useState<ProfileInfo | undefined>();
  const identityApi = useApi(identityApiRef);

  useEffect(() => {
    identityApi.getProfileInfo().then(setProfile);
  }, [setProfile, identityApi]);

  return profile?.email ?? '';
};
