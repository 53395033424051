import {scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {createScaffolderFieldExtension} from '@backstage/plugin-scaffolder-react';
import {ProjectNameValidatorField} from './ProjectNameField';
import {schema} from './schema';

export const ProjectNameValidatorExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'ProjectNameValidatorExtension',
    component: ProjectNameValidatorField,
    schema,
  }),
);
