import React, {useEffect} from 'react';
import {FormControl, InputLabel, FormHelperText, Input} from '@material-ui/core';
import {useGetEmail} from './useGetEmail';
import {useHasChanged} from '../../hooks';
import {getUiOptions} from '../../utils';
import {FieldProps, Options} from './schema';

/*
 Gets the full name of the logged in user
 navigate to /create/edit to test custom fields
*/
export const EmailField: React.FC<FieldProps> = ({onChange, formData, rawErrors, uiSchema}) => {
  const email = useGetEmail();
  const hasChanged = useHasChanged(email);
  const {title, description} = getUiOptions<Options>(uiSchema);

  useEffect(() => {
    if (!hasChanged) return;
    onChange(email);
  }, [onChange, email, hasChanged]);

  return (
    <FormControl error={rawErrors?.length > 0 && !formData}>
      <InputLabel>{title}</InputLabel>
      <Input value={email} disabled />
      <FormHelperText>{description}</FormHelperText>
    </FormControl>
  );
};
