import React, {useEffect} from 'react';
import {FormControl, InputLabel, FormHelperText, Input} from '@material-ui/core';
import {deriveFieldProperties, serializeBitbucketRepoName} from './utils';
import {FieldProps} from './schema';
import {useHasChanged} from '../../hooks';
import {useSetRepoSlug} from './useIsRepoSlugValid';

/*
 Verifies the entered repo name is valid
*/
export const BitbucketRepoNameField: React.FC<FieldProps> = ({
  formData,
  rawErrors,
  formContext,
  onChange,
  uiSchema,
}) => {
  const {repoSlugInitial, hasProjectName} = deriveFieldProperties(formContext, uiSchema);
  const {repoSlug, setRepoSlug} = useSetRepoSlug(repoSlugInitial);
  const {repoUrl, title, description} = serializeBitbucketRepoName(repoSlugInitial, uiSchema);
  const hasChanged = useHasChanged(repoUrl);

  // Call onChange and validate the repoSlug whenever it changes
  useEffect(() => {
    onChange(repoUrl);
    setRepoSlug(repoSlugInitial);
  }, [hasChanged, hasProjectName, onChange, repoSlugInitial, repoUrl, setRepoSlug]);

  return (
    <FormControl error={rawErrors?.length > 0 && !formData}>
      <InputLabel>{title}</InputLabel>
      {/* display the repoSlug value, but make it read-only/disabled */}
      <Input value={repoSlug} disabled />
      <FormHelperText>{description}</FormHelperText>
    </FormControl>
  );
};
