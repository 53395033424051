import React from 'react';
import {Route} from 'react-router';
import {CatalogEntityPage, CatalogIndexPage, catalogPlugin} from '@backstage/plugin-catalog';
import {catalogImportPlugin} from '@backstage/plugin-catalog-import';
import {ScaffolderPage, scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {ScaffolderFieldExtensions} from '@backstage/plugin-scaffolder-react';
import {FullNameExtension} from './scaffolder/FullNameExtension';
import {EmailExtension} from './scaffolder/EmailExtension';
import {BitbucketRepoNameExtension} from './scaffolder/BitbucketRepoNameValidatorExtension';
import {ProjectNameValidatorExtension} from './scaffolder/ProjectNameValidatorExtension';
import {orgPlugin} from '@backstage/plugin-org';
import {SearchPage} from '@backstage/plugin-search';
import {TechDocsIndexPage, techdocsPlugin, TechDocsReaderPage} from '@backstage/plugin-techdocs';
import {TechDocsAddons} from '@backstage/plugin-techdocs-react';
import {Mermaid} from 'backstage-plugin-techdocs-addon-mermaid';
import {ExpandableNavigation, ReportIssue} from '@backstage/plugin-techdocs-module-addons-contrib';
import {UserSettingsPage} from '@backstage/plugin-user-settings';
import {apis} from './apis';
import {entityPage} from './components/catalog/EntityPage';
import {searchPage} from './components/search/SearchPage';
import {Root} from './components/Root';

import {AlertDisplay, OAuthRequestDialog} from '@backstage/core-components';
import {createApp} from '@backstage/app-defaults';
import {FlatRoutes, AppRouter} from '@backstage/core-app-api';
import {CatalogGraphPage} from '@backstage/plugin-catalog-graph';
import {RequirePermission} from '@backstage/plugin-permission-react';
import {catalogEntityCreatePermission} from '@backstage/plugin-catalog-common/alpha';

// import { BackstageTheme, lightTheme } from '@backstage/theme';
// import { BackstageOverrides } from '@backstage/core-components';
import {parivedaThemeWithOverrides} from '../src/components/Root/Theme';
import {ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import LightIcon from '@material-ui/icons/WbSunny';

import {microsoftAuthApiRef} from '@backstage/core-plugin-api';
import {SignInPage} from '@backstage/core-components';
import {isLocal} from './utils';

import {HomepageCompositionRoot} from '@backstage/plugin-home';
import {HomePage} from './components/home/HomePage';
import {CatalogImportPage} from './components/catalog/CatalogImportPage';
import {VersionInfoCardPage} from '@internal/plugin-version-info-card';

const app = createApp({
  apis,
  bindRoutes({bind}) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot as unknown as undefined,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  // Comment out the following for local development
  components: isLocal
    ? {}
    : {
        SignInPage: props => (
          <SignInPage
            {...props}
            auto
            provider={{
              id: 'azure-auth-provider',
              title: 'Microsoft',
              message: 'Sign in using Microsoft',
              apiRef: microsoftAuthApiRef,
            }}
          />
        ),
      },
  themes: [
    {
      id: 'pariveda-theme',
      title: 'My Custom Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({children}) => (
        <ThemeProvider theme={parivedaThemeWithOverrides}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path='/' element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path='/catalog' element={<CatalogIndexPage />} />
    <Route path='/catalog/:namespace/:kind/:name' element={<CatalogEntityPage />}>
      {entityPage}
    </Route>
    <Route path='/docs' element={<TechDocsIndexPage />} />
    <Route path='/docs/:namespace/:kind/:name/*' element={<TechDocsReaderPage />}>
      <TechDocsAddons>
        <ReportIssue />
        <ExpandableNavigation />
        <Mermaid config={{theme: 'dark'}} />
      </TechDocsAddons>
    </Route>
    <Route path='/create' element={<ScaffolderPage />}>
      <ScaffolderFieldExtensions>
        <FullNameExtension />
        <EmailExtension />
        <BitbucketRepoNameExtension />
        <ProjectNameValidatorExtension />
      </ScaffolderFieldExtensions>
    </Route>
    {/* <Route path='/api-docs' element={<ApiExplorerPage />} />
    <Route path='/tech-radar' element={<TechRadarPage width={1500} height={800} />} /> */}
    <Route
      path='/catalog-import'
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path='/search' element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path='/settings' element={<UserSettingsPage />} />
    <Route path='/catalog-graph' element={<CatalogGraphPage />} />
    <Route path='/version-info-card' element={<VersionInfoCardPage />} />
  </FlatRoutes>
);

const App = app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);

export default App;
