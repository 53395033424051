import {useGetEmail} from '../EmailExtension/useGetEmail';

const determineFullNameFromEmail = (email: string | undefined) => {
  if (!email) return '';
  const indexofAtSign = email.indexOf('@');
  const indexofDot = email.indexOf('.');
  const fullName = email.substring(0, indexofAtSign);
  const first = fullName.substring(0, indexofDot);
  const last = fullName.substring(indexofDot + 1);
  return `${first} ${last}`;
};

export const useGetFullName = () => {
  const fullName = determineFullNameFromEmail(useGetEmail());
  return fullName;
};
