import React, {useState} from 'react';
import {Button, Grid, Modal, Typography, Link, Paper, Fade, Box} from '@material-ui/core';

export type ModalButtonProps = {
  href: string;
  children: string;
  title: string;
  icon?: React.ReactNode;
};

export const ModalButton: React.FC<ModalButtonProps> = props => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        variant='contained'
        onClick={() => setShow(!show)}
        color='primary'
        startIcon={props.icon}
      >
        {props.title}
      </Button>
      <Modal
        open={show}
        title={props.title}
        onClose={() => setShow(false)}
        style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        closeAfterTransition
      >
        <Fade in={show}>
          <Paper style={{maxWidth: 500}}>
            <Box p={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant='h4'>{props.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{props.children}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box pt={2} />
                </Grid>
                <Grid container item xs={12} justifyContent='space-between'>
                  <Button onClick={() => setShow(false)} variant='contained'>
                    Exit
                  </Button>
                  <Link href={props.href} target='_blank'>
                    <Button variant='contained' color='primary' onClick={() => setShow(false)}>
                      Continue
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
};
