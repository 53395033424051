/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {PropsWithChildren} from 'react';
import {Link, makeStyles, Typography} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import QueueComponentIcon from '@material-ui/icons/Queue';
import CreateIcon from '@material-ui/icons/Create';
import CodeComponentIcon from '@material-ui/icons/Code';
import NoteAddComponentIcon from '@material-ui/icons/NoteAdd';
import Collapse from '@material-ui/core/Collapse';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {NavLink} from 'react-router-dom';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import {SidebarSearchModal} from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CategoryIcon from '@material-ui/icons/Category';
import {isDev} from '../../utils';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
  devLogo: {
    color: 'yellow',
    textAlign: 'center',
    paddingTop: '20px',
    width: '100%',
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const {isOpen} = useSidebarOpenState();
  const isDevEnvironment = isDev();

  if (isDevEnvironment) {
    return (
      <div className={classes.devLogo}>
        <Link component={NavLink} to='/' underline='none' aria-label='Home'>
          {isOpen ? <LogoFull height='18' /> : <LogoIcon />}
        </Link>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Typography variant='h6'>⚠️ Test Environment</Typography>
        </div>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Link component={NavLink} to='/' underline='none' className={classes.link} aria-label='Home'>
        {isOpen ? <LogoFull height='18' /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({children}: PropsWithChildren<{}>) => {
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked(prev => !prev);
  };

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarGroup label='Search' icon={<SearchIcon />} to='/search'>
          <SidebarSearchModal />
        </SidebarGroup>
        <SidebarDivider />
        <SidebarGroup label='Menu' icon={<MenuIcon />}>
          {/* Global nav, not org-specific */}
          <SidebarItem icon={HomeIcon} to='/' text='Home' />
          <SidebarItem icon={CategoryIcon} to='catalog' text='Catalog' />
          {/* <SidebarItem icon={ExtensionIcon} to='api-docs' text='APIs' /> */}
          <SidebarItem icon={LibraryBooks} to='docs' text='Docs' />
          <div>
            <SidebarItem text='Create...' icon={CreateComponentIcon} onClick={handleChange} />
            <div style={{marginLeft: '24px'}}>
              <Collapse in={checked}>
                <SidebarItem
                  icon={NoteAddComponentIcon}
                  to='create/templates/default/pariveda-ip-template'
                  text='Backstage IP'
                />
              </Collapse>
              <Collapse in={checked}>
                <SidebarItem icon={CodeComponentIcon} to='catalog-import' text='Register Code' />
              </Collapse>
              <Collapse in={checked}>
                <SidebarItem icon={QueueComponentIcon} to='create' text='Templates' />
              </Collapse>
              <Collapse in={checked}>
                <SidebarItem icon={CreateIcon} to='create/edit' text='New Template' />
              </Collapse>
            </div>
          </div>
          {/* End global nav */}
          <SidebarDivider />
          {/* <SidebarScrollWrapper>
          <SidebarItem icon={MapIcon} to='tech-radar' text='Tech Radar' />
        </SidebarScrollWrapper> */}
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup label='Settings' icon={<UserSettingsSignInAvatar />} to='/settings'>
          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
