import {UiSchema} from '@rjsf/utils';
import {getUiOptions} from '../../utils';
import {Options} from './schema';
import {slugify} from '../utils/bitbucketUtilities';

export const deriveFieldProperties = (
  formContext: {formData: {[field: string]: string | undefined}},
  uiSchema: UiSchema,
) => {
  const {repoNameProperty} = getUiOptions<Options>(uiSchema);
  // this is the path of the other field to derive the repo name from
  const projectNameUnclean = formContext?.formData[repoNameProperty ?? 'projectName'] as
    | string
    | undefined;
  const projectNameStr = projectNameUnclean ?? '';
  // get the slugified name
  const repoSlugInitial = slugify(projectNameStr);

  // detect if there is a project name that has been typed yet
  const hasProjectName = projectNameUnclean !== undefined;

  return {repoSlugInitial, hasProjectName};
};

export const serializeBitbucketRepoName = (repoSlug: string, uiSchema: UiSchema) => {
  const {allowedOwners, allowedProjects, title, description, repoNameProperty} =
    getUiOptions<Options>(uiSchema);
  if (
    !allowedOwners ||
    !allowedProjects ||
    !Array.isArray(allowedOwners) ||
    !Array.isArray(allowedProjects) ||
    allowedOwners.length === 0 ||
    allowedProjects.length === 0 ||
    !title ||
    !description
  )
    throw new Error(
      'Missing required ui:schema options, one of: allowedOwners, allowedProjects, title, description',
    );

  const owner = allowedOwners[0];
  const project = allowedProjects[0];
  const repoUrl = `bitbucket.org?owner=${owner}&repo=${repoSlug}&workspace=${owner}&project=${project}`;
  return {repoUrl, title, description, repoNameProperty};
};
