import React, {useEffect} from 'react';
import {FieldProps} from '@rjsf/utils';
import {FormControl, InputLabel, FormHelperText, Input} from '@material-ui/core';
import {useGetFullName} from './useGetFullName';

/*
 Gets the full name of the logged in user
 navigate to /create/edit to test custom fields
*/
export const FullNameField = ({onChange, required, formData, rawErrors}: FieldProps<string>) => {
  const fullName = useGetFullName();

  useEffect(() => {
    onChange(fullName);
  }, [onChange, fullName]);

  return (
    <FormControl
      margin='normal'
      required={required}
      error={rawErrors !== undefined && rawErrors.length > 0 && !formData}
    >
      <InputLabel>Owner Name</InputLabel>
      <Input value={fullName} disabled />
      <FormHelperText>Name of IP owner will be current user</FormHelperText>
    </FormControl>
  );
};
