import {
  CustomFieldExtensionSchema,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';

export const schema: CustomFieldExtensionSchema = {
  uiOptions: {
    type: 'object',
    required: ['title', 'description'],
    properties: {
      title: {
        type: 'string',
        description: 'The label of the email field',
      },
      description: {
        type: 'string',
        description: 'The sublabel or description of the email field',
      },
    },
  },
  returnValue: {
    type: 'string',
  },
};

export type Options = {
  title: string;
  description: string;
};

export type FieldProps = FieldExtensionComponentProps<string, Options>;
