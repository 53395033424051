import {
  CustomFieldExtensionSchema,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';

export const schema: CustomFieldExtensionSchema = {
  uiOptions: {
    type: 'object',
    required: ['allowedOwners', 'allowedProjects', 'title', 'description'],
    properties: {
      allowedOwners: {
        type: 'array',
        description: 'The bitbucket owner of the repo',
        items: {
          type: 'string',
        },
      },
      allowedProjects: {
        type: 'array',
        description: 'The bitbucket project of the repo',
        items: {
          type: 'string',
        },
      },
      title: {
        type: 'string',
        description: 'The label of the repo name field',
      },
      description: {
        type: 'string',
        description: 'The sublabel or description of the repo name field',
      },
      repoNameProperty: {
        type: 'string',
        description: 'The top-level form property name to use as the repo name to validate',
      },
    },
  },
  returnValue: {
    type: 'string',
  },
};

export type Options = {
  allowedOwners: string[];
  allowedProjects: string[];
  title: string;
  description: string;
  repoNameProperty?: string;
};

export type FieldProps = FieldExtensionComponentProps<string, Options>;
