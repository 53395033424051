import {ApiRef, createApiRef} from '@backstage/core-plugin-api';
import {Config} from '@backstage/config';

const repoExistsRequestOptions: RequestInit = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
};

export const bitbucketUtilitiesApi = (config: Config): UtilitiesApi => {
  const baseUrl = config.getString('backend.baseUrl');
  const repoExists = async (repo_slug: string) => {
    if (!repo_slug) {
      throw new Error('repo_slug is required');
    }

    try {
      const url = `${baseUrl}/api/utilities/repo_exists?${new URLSearchParams({
        repo_slug,
      })}`;
      const response = await fetch(url, repoExistsRequestOptions);
      if (response.status !== 200) {
        throw new Error(`${response.status} ${response.statusText}, ${await response.text()}`);
      }
      const data = (await response.json()) as {result: boolean};
      const doesRepoExist = data.result;
      return doesRepoExist;
    } catch (e) {
      throw new Error(`Unexpected response from validation, ${e}`);
    }
  };

  const inviteUser = async (repoSlug: string, userEmail: string) => {
    if (!repoSlug || !userEmail) {
      throw new Error('repoSlug and userEmail are required');
    }
    const inviteUserRequestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_email: userEmail,
        repo_slug: repoSlug,
      }),
    };
    try {
      const url = `${baseUrl}/api/utilities/invite_user`;

      const response = await fetch(url, inviteUserRequestOptions);
      if (response.status !== 200) {
        throw new Error(`${response.status} ${response.statusText}, ${await response.text()}`);
      }
      const data = (await response.json()) as {result: boolean};
      const success = data.result;
      return success;
    } catch (e) {
      return false;
    }
  };

  const doesUserHavePermission = async (repoSlug: string, userEmail: string) => {
    if (!repoSlug || !userEmail) {
      throw new Error('repoSlug and userEmail are required');
    }
    const doesUserHavePermissionRequestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const params = {
      repo_slug: repoSlug,
      user_email: userEmail,
    };
    try {
      const url = `${baseUrl}/api/utilities/get_permissions_for_user?${new URLSearchParams(
        params,
      )}`;
      const response = await fetch(url, doesUserHavePermissionRequestOptions);
      if (response.status !== 200) {
        throw new Error(`${response.status} ${response.statusText}, ${await response.text()}`);
      }
      const data = (await response.json()) as {result: boolean};
      const hasPermission = data.result;
      return hasPermission;
    } catch (e) {
      return false;
    }
  };
  return {repoExists, inviteUser, doesUserHavePermission};
};

export type UtilitiesApi = {
  repoExists(repo_slug: string, baseUrl: string): Promise<boolean>;
  inviteUser(repoSlug: string, userEmail: string): Promise<boolean>;
  doesUserHavePermission(repoSlug: string, userEmail: string): Promise<boolean>;
};

export const utilitiesApiRef: ApiRef<UtilitiesApi> = createApiRef({
  id: 'utilitiesapi.custom',
});
