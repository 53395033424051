import {scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {createScaffolderFieldExtension} from '@backstage/plugin-scaffolder-react';
import {EmailField} from './EmailField';
import {schema} from './schema';

export const EmailExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'EmailExtension',
    component: EmailField,
    schema,
  }),
);
