import React from 'react';
import {Accordion, AccordionSummary, AccordionDetails, Typography} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Define a type for each FAQ item
type FAQItemProps = {
  question: string;
  answer: string;
};

// Define a type for the FAQ component props
type FAQProps = {
  faqs: FAQItemProps[];
};

// FAQ Item Component
const FAQItem: React.FC<FAQItemProps> = ({question, answer}) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

// FAQ Component
const FAQ: React.FC<FAQProps> = ({faqs}) => {
  return (
    <div>
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default FAQ;
