import {scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  CustomFieldExtensionSchema,
} from '@backstage/plugin-scaffolder-react';
import {FullNameField} from './FullNameField';

const FullNameExtensionSchema: CustomFieldExtensionSchema = {
  uiOptions: {
    type: 'object',
    properties: {},
  },
  returnValue: {
    type: 'string',
  },
};

export const FullNameExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'FullNameExtension',
    component: FullNameField,
    schema: FullNameExtensionSchema,
  }),
);
