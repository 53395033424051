import {
  Content,
  ContentHeader,
  Header,
  InfoCard,
  Page,
  SupportButton,
} from '@backstage/core-components';
import {
  configApiRef,
  createRoutableExtension,
  createRouteRef,
  useApi,
} from '@backstage/core-plugin-api';
import {Grid, Typography, makeStyles} from '@material-ui/core';
import React from 'react';
import {catalogImportPlugin} from '@backstage/plugin-catalog-import';
import {ImportStepper} from '@backstage/plugin-catalog-import';
import {CodeAreaCatalogInfo, CodeAreaMkDocs} from '../templating/CodeArea';

const rootRouteRef = createRouteRef({
  id: 'catalog-import',
});

const useStyles = makeStyles({
  instructionCard: {
    height: '100%',
  },
  cardRow: {
    padding: '30px 2px 50px 2px',
  },
});

const CustomCatalogImportPage = () => {
  const configApi = useApi(configApiRef);
  const classes = useStyles();
  const appTitle = configApi.getOptional('app.title') || 'Backstage';

  return (
    <Page themeId='home'>
      <Header title='Register an existing component' />
      <Content>
        <ContentHeader title={`Start tracking your component in ${appTitle}`}>
          <SupportButton />
        </ContentHeader>
        {/* need a way to prevent text duplication between here and home page */}

        <Grid container spacing={2} direction='row' className={classes.cardRow}>
          <Grid item xs={12} md={8} lg={6} xl={6}>
            <ImportStepper />
          </Grid>
          <Grid item xs={12} md={4} lg={6} xl={6}>
            <InfoCard title='ADD EXISTING IP' className={classes.instructionCard}>
              <div>
                <Typography variant='h6'>
                  To register a component in Pariveda Backstage follow these steps:
                </Typography>
                <ol>
                  <li>URLs must be under the Pariveda Solutions bitbucket workspace</li>
                  <li>
                    Add <strong>backstage-ip-capture@parivedasolutions.com </strong> as a user to
                    your repo as <strong>ADMIN</strong>
                  </li>
                  <li>Create catalog-info.yaml file in root directory of your repo</li>
                  <ul>
                    <li>Copy and paste code block below into catalog-info.yaml file</li>
                    <li>Edit catalog-info file to match repo details</li>
                    <ul>
                      <li>
                        DO NOT remove <strong>parivedasolutions</strong> as the owner
                      </li>
                    </ul>
                  </ul>
                  <li>
                    Create mkdocs.yml file in the root directory of your repo. This config is used
                    to create project documentation.
                  </li>
                  <ul>
                    <li>Copy and paste the code block below into your mkdocs.yml file</li>
                    <li>
                      Edit this confg file with your site information, navigation, plugins, etc.
                    </li>
                  </ul>
                  <li>Enter the component URL ending in catalog-info.yaml</li>
                  <li>Click "Analyze"</li>
                  <li>If successful, you will have the option to register another component.</li>
                  <ul>
                    <li>
                      You can now view your component under the homepage when filtering kind as
                      "Component."
                    </li>
                  </ul>
                </ol>
              </div>
            </InfoCard>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5'>Example code for catalog-info.yaml</Typography>
          <div
            style={{
              padding: '5px',
              boxShadow: '10px',
              backgroundColor: '#444444',
            }}
          >
            <CodeAreaCatalogInfo />
          </div>
        </Grid>
        <Grid container style={{marginTop: '40px'}} item xs={12}>
          <Typography variant='h5'>Example code for mkdocs.yaml</Typography>
          <div
            style={{
              padding: '5px',
              boxShadow: '10px',
              backgroundColor: '#444444',
            }}
          >
            <CodeAreaMkDocs />
          </div>
        </Grid>
      </Content>
    </Page>
  );
};

export const CatalogImportPage = catalogImportPlugin.provide(
  createRoutableExtension({
    name: 'CatalogImportPage',
    component: async () => CustomCatalogImportPage,
    mountPoint: rootRouteRef,
  }),
);
