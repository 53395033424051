import {createPlugin, createRoutableExtension} from '@backstage/core-plugin-api';

import {rootRouteRef} from './routes';

export const versionInfoCardPlugin = createPlugin({
  id: 'version-info-card',
  routes: {
    root: rootRouteRef,
  },
});

export const VersionInfoCardPage = versionInfoCardPlugin.provide(
  createRoutableExtension({
    name: 'VersionInfoCardPage',
    component: () =>
      import('./components/VersionInfoCard/VersionInfoCard').then(m => m.VersionInfoCard),
    mountPoint: rootRouteRef,
  }),
);
