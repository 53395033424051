import {scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {createScaffolderFieldExtension} from '@backstage/plugin-scaffolder-react';
import {BitbucketRepoNameField} from './BitbucketRepoNameField';
import {schema} from './schema';

export const BitbucketRepoNameExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'BitbucketRepoNameExtension',
    component: BitbucketRepoNameField,
    schema,
  }),
);
